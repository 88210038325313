.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;

    .wrapper-body {
        flex: 1;
        background-color: white;
        display: flex;
        flex-direction: column;

        img {
            align-self: center;
            margin-bottom: 20px;
        }
    }

    .header {
        min-height: 50px;
    }

    .footer {
        align-self: center;
        padding-top: 5px;

        img {
            width: 450px;
        }
    }
}
