.pricing {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .toggle {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        .mode {
            font-size: 17px;
            font-weight: bold;

            &.monthly {
                color: #d5185e;
            }
            &.yearly {
                color: #445aa8;
            }
        }
    }

    .licenses {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 10px;

        .card {
            margin: 0 60px 20px 0;
            display: flex;
            border-radius: 12px;
            box-shadow: 1px 1px 4px 3px rgb(0 0 0 / 20%),
                0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

            &:last-of-type {
                margin-right: 0;
            }

            .license {
                flex: 1;
                display: flex;
                flex-direction: column;
                width: 230px;
                border-radius: 12px;
                border: 2px solid grey;

                .header-color {
                    height: 37px;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    color: white;
                    font-size: 24px;
                    vertical-align: middle;
                    display: flex;
                    align-items: center;
                    padding-left: 7px;
                    border-bottom: 2px solid grey;
                    font-weight: bold;
                }

                .circles {
                    display: flex;
                    margin-bottom: 20px;
                    justify-content: flex-end;
                    margin-top: -10px;
                    z-index: 1;

                    div {
                        height: 20px;
                        border: 3px solid white;
                        width: 20px;
                        margin: 0 3px;
                        border-radius: 50%;
                    }
                }

                .content {
                    padding: 8px;
                    flex: 1;

                    .name {
                        display: flex;
                        justify-content: center;
                        font-size: 24px;
                    }

                    .price {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 15px;
                        font-weight: bold;

                        .yearly-license {
                            font-weight: normal;
                            font-size: 12px;
                            height: 15px;
                        }
                    }

                    .description {
                        display: flex;
                        margin-bottom: 20px;
                        justify-content: center;
                    }

                    .separators {
                        display: flex;
                        margin-bottom: 20px;
                        justify-content: center;

                        div {
                            height: 10px;
                            width: 10px;
                            margin: 0 4px;
                            border-radius: 50%;
                        }
                    }

                    .items {
                        display: flex;
                        flex-direction: column;

                        .item {
                            display: flex;
                            align-items: flex-start;
                            margin: 2px 0;

                            .check {
                                color: #60bb46;
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }

            .register {
                display: flex;
                justify-content: center;
                margin: 10px 0 20px 0;
                justify-self: flex-end;

                button {
                    background-color: #368b1e;
                    color: white;
                    padding: 8px;
                    outline: none;
                    border: none;
                    border-radius: 3px;
                    cursor: pointer;
                }
            }
        }
    }
}
