.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}
.wrapper .wrapper-body {
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.wrapper .wrapper-body img {
  align-self: center;
  margin-bottom: 20px;
}
.wrapper .header {
  min-height: 50px;
}
.wrapper .footer {
  align-self: center;
  padding-top: 5px;
}
.wrapper .footer img {
  width: 450px;
}
.pricing {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.pricing .toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.pricing .toggle .mode {
  font-size: 17px;
  font-weight: bold;
}
.pricing .toggle .mode.monthly {
  color: #d5185e;
}
.pricing .toggle .mode.yearly {
  color: #445aa8;
}
.pricing .licenses {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}
.pricing .licenses .card {
  margin: 0 60px 20px 0;
  display: flex;
  border-radius: 12px;
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pricing .licenses .card:last-of-type {
  margin-right: 0;
}
.pricing .licenses .card .license {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 230px;
  border-radius: 12px;
  border: 2px solid grey;
}
.pricing .licenses .card .license .header-color {
  height: 37px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  font-size: 24px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  padding-left: 7px;
  border-bottom: 2px solid grey;
  font-weight: bold;
}
.pricing .licenses .card .license .circles {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
  margin-top: -10px;
  z-index: 1;
}
.pricing .licenses .card .license .circles div {
  height: 20px;
  border: 3px solid white;
  width: 20px;
  margin: 0 3px;
  border-radius: 50%;
}
.pricing .licenses .card .license .content {
  padding: 8px;
  flex: 1;
}
.pricing .licenses .card .license .content .name {
  display: flex;
  justify-content: center;
  font-size: 24px;
}
.pricing .licenses .card .license .content .price {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  font-weight: bold;
}
.pricing .licenses .card .license .content .price .yearly-license {
  font-weight: normal;
  font-size: 12px;
  height: 15px;
}
.pricing .licenses .card .license .content .description {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
}
.pricing .licenses .card .license .content .separators {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
}
.pricing .licenses .card .license .content .separators div {
  height: 10px;
  width: 10px;
  margin: 0 4px;
  border-radius: 50%;
}
.pricing .licenses .card .license .content .items {
  display: flex;
  flex-direction: column;
}
.pricing .licenses .card .license .content .items .item {
  display: flex;
  align-items: flex-start;
  margin: 2px 0;
}
.pricing .licenses .card .license .content .items .item .check {
  color: #60bb46;
  margin-right: 4px;
}
.pricing .licenses .card .register {
  display: flex;
  justify-content: center;
  margin: 10px 0 20px 0;
  justify-self: flex-end;
}
.pricing .licenses .card .register button {
  background-color: #368b1e;
  color: white;
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.registration-form {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.registration-form .card {
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.registration-form .form {
  display: flex;
  flex-direction: column;
}
.registration-form .form td {
  padding: 8px 5px;
}
.registration-form .form td.label {
  width: 130px;
}
.registration-form .form .language-radio {
  display: flex;
  margin-bottom: 5px;
}
.registration-form .form .language-radio label {
  display: flex;
}
.registration-form .form .language-radio label img {
  margin: 0 2px;
  width: 25px;
}
.registration-form .form .field-input {
  position: relative;
}
.registration-form .form .field-input input {
  padding: 5px 10px;
  width: 200px;
}
.registration-form .form .field-input .error-icon {
  position: absolute;
  color: red;
  top: 8px;
  right: 5px;
}
.registration-form .form .submit-wrapper {
  text-align: center;
}
.registration-form .form .submit-wrapper button {
  margin-top: 10px;
  margin-right: 10px;
  background-color: #368b1e;
  color: white;
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.registration-form .form .submit-errors {
  margin-top: 20px;
  color: red;
}
html,
body,
#root,
.body,
.inner {
  height: 100%;
  margin: 0;
  background-color: #0485ae;
}
.body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.body .image-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.body .image-wrapper .title {
  color: #96d4ef;
  font-size: 17px;
  margin-bottom: 5px;
  text-align: center;
}
.body .image-wrapper .synthesis {
  height: 640px;
}
.body .image-wrapper .newsletter-form {
  display: flex;
  flex-direction: column;
  margin: -55px 0 20px 0;
}
.body .image-wrapper .newsletter-form .subscribe {
  align-self: center;
}
.body .image-wrapper .form-field {
  margin-bottom: 5px;
}
.body .image-wrapper .form-field .input-wrapper {
  position: relative;
}
.body .image-wrapper .form-field .input-wrapper .icon {
  position: absolute;
  font-size: 18px;
  color: #20409b;
  top: 3px;
  left: 4px;
}
.body .image-wrapper .form-field .input-wrapper input {
  width: 250px;
  outline: none;
  border-radius: 3px;
  border: 1px solid #20409b;
  padding: 4px 24px;
}
.body .image-wrapper .form-field .error {
  color: #af0505;
  font-size: 15px;
}
