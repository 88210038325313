.registration-form {
    display: flex;
    justify-content: center;
    padding: 10px;

    .card {
        box-shadow: 1px 1px 4px 3px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
            0px 1px 3px 0px rgb(0 0 0 / 12%);
    }

    .form {
        display: flex;
        flex-direction: column;

        td {
            padding: 8px 5px;

            &.label {
                width: 130px;
            }
        }

        .language-radio {
            display: flex;
            margin-bottom: 5px;

            label {
                display: flex;

                img {
                    margin: 0 2px;
                    width: 25px;
                }
            }
        }

        .field-input {
            position: relative;

            input {
                padding: 5px 10px;
                width: 200px;
            }

            .error-icon {
                position: absolute;
                color: red;
                top: 8px;
                right: 5px;
            }
        }

        .submit-wrapper {
            text-align: center;

            button {
                margin-top: 10px;
                margin-right: 10px;
                background-color: #368b1e;
                color: white;
                padding: 8px;
                outline: none;
                border: none;
                border-radius: 3px;
                cursor: pointer;
            }
        }

        .submit-errors {
            margin-top: 20px;
            color: red;
        }
    }
}
